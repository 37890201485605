/* BotPage.css */

/* General Styling */
body {
  font-family: 'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Kaku Gothic ProN', 'MS PGothic', sans-serif;
  background-color: #f0f8ff;
  color: #333;
  line-height: 1.6;
}

body:lang(ja) {
  font-family: 'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Kaku Gothic ProN', 'MS PGothic', sans-serif;
}

.bot-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Header Styling */
.header {
  display: flex;
  align-items: center;
  background-color: #e0ffe0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.logo img {
  height: 60px;
  width: auto;
  margin-right: 15px;
}

.header h1 {
  font-size: 1.8rem;
  color: #333;
}

/* Language Selector */
.language-selector {
  text-align: right;
  margin-bottom: 20px;
}

.language-selector label {
  font-weight: bold;
}

.language-selector select {
  margin-left: 10px;
}

/* Service Description */
.service-description {
  text-align: left;
  margin-bottom: 40px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Agent List Styling */
.agent-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.agent-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
  transition: transform 0.3s ease;
}

.agent-card:hover {
  transform: translateY(-5px);
}

.agent-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

/* Request Tokuru Agent Section */
.request-agent {
  margin-top: 40px;
}

.expandable-heading {
  cursor: pointer;
  background-color: #e0ffe0;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 1.5rem;
  text-align: left;
}

.request-description {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  text-align: left;
}

/* Agent Form Styling */
.bot-form {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bot-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.bot-form input,
.bot-form textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.bot-form button {
  background-color: #32cd32;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bot-form button:hover {
  background-color: #2e8b57;
}

/* Inquiry Form Container */
.inquiry-form-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Section Heading */
.section-heading {
  font-size: 1.8rem;
  margin-bottom: 20px;
  text-align: left;
  background-color: #e0ffe0;
  padding: 5px;
  border-radius: 10px;
}

/* Inquiry Form Styling */
.inquiry-form {
  margin-top: 20px;
}

.inquiry-form .form-group {
  margin-bottom: 20px;
}

.inquiry-form label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

.inquiry-form input,
.inquiry-form textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.inquiry-form input:focus,
.inquiry-form textarea:focus {
  border-color: #32cd32;
  outline: none;
  background-color: #fff;
}

.inquiry-form textarea {
  min-height: 120px;
}

.inquiry-form button {
  background-color: #32cd32;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.inquiry-form button:hover {
  background-color: #2e8b57;
}

.inquiry-form button:disabled {
  background-color: #aaa;
  cursor: not-allowed;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 15px;
}

/* Inquiry Section */
.inquiry-section {
  margin-top: 40px;
  text-align: center;
}

/* Agent Request Button */
.agent-request-button {
  background-color: #32cd32;
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Toggle Inquiry Button */
.toggle-inquiry-button {
  background: none;
  color: blue;
  padding: 0;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
}

.toggle-inquiry-button:hover {
  color: darkblue;
}

/* Footer Styling */
.footer {
  text-align: center;
  margin-top: 40px;
  padding: 20px;
  background-color: #333;
  color: #fff;
  border-radius: 10px;
}

/* Header Copy Styling */
.header-copy {
  text-align: center;
  background-color: #caffb9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 1.4rem;
}

.footer .social-links {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 10px;
}

.footer .social-links li {
  display: inline-block;
}

.footer .social-links a {
  color: #e0d9d9; /* Adjust color as needed */
  text-decoration: none;
  font-size: 1.5rem;
}

.footer .social-links a:hover {
  color: #0077b5; /* Adjust hover color for LinkedIn or customize per platform */
}
